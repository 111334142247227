<template>
  <b-dropdown id="project-dropdown" variant="outline-white" right no-caret>
    <template #button-content>
      {{ $t("page.results.changeProject") }}
    </template>
    <template v-for="(project, index) in projectOptions">
      <b-dropdown-item
        :key="index"
        :active="isActiveProject(project._id)"
        @click="!isActiveProject(project._id) && setActiveProject(project._id)"
      >
        {{ project.name }}
      </b-dropdown-item>
    </template>
  </b-dropdown>
</template>

<script>
import orderBy from "lodash/orderBy";

export default {
  computed: {
    routeName() {
      return this.$route.name;
    },
    clientId() {
      return this.$store.getters["auth/getClientId"];
    },
    activeProjectId() {
      return this.$store.getters["project/getActiveProjectId"];
    },
    groupProjects() {
      return this.$store.getters["project/getProjectList"];
    },
    closedProjects() {
      return this.groupProjects && this.groupProjects.length === 3
        ? this.groupProjects[2].list // 'closed' projects
        : [];
    },
    projectOptions() {
      return orderBy(
        this.closedProjects,
        (project) => project.name.toLowerCase(),
        "asc"
      ).map((p) => ({
        _id: p._id,
        name: p.name,
      }));
    },
  },
  methods: {
    async setActiveProject(projectId) {
      try {
        this.$store.commit("loader/SET_LOADER", {});

        await this.$store.dispatch("analysis/retrieveAnalysisData", {
          projectId,
          clientId: this.clientId,
        });

        await this.$store.dispatch("analysis/retrieveNorms");

        this.$router
          .replace({
            name: this.routeName,
            params: { id: projectId, is404: true },
          })
          .catch(() => {});
      } catch (error) {
      } finally {
        this.$store.dispatch("loader/close");
      }
    },
    isActiveProject(projectId) {
      return this.activeProjectId === projectId;
    },
  },
};
</script>

<style lang="scss" scoped>
#project-dropdown {
  /deep/ {
    button {
      width: 160px;
      color: white;
      text-align: start;
      box-shadow: unset;
      flex: unset;
      background: transparent
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='white' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
        no-repeat right 0.75rem center/8px 10px;
    }
    .dropdown-menu {
      max-height: 300px;
      overflow-y: auto;
    }
  }
}
</style>
